import { format } from "date-fns";


const numberWithCommas = (plainNumber) => {
    if (plainNumber) {
        return plainNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return "0";
    }
};

const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
};


const toNumber = (plainNumber) => {
    var n = Number(plainNumber);
    if (isNaN(n)) {
        return 0;
    } else {
        return n;
    }
};

const toPrettyDate = (plainDate) => {
    try {
        return (format(
            new Date(plainDate + "T00:00:00.000"),
            "MMMM d, uuuu"
        ));

    } catch (err) {
        return err.message;
    }
};

const usePrettyNumbers = () => {
    return { formatPhoneNumber, numberWithCommas, toPrettyDate, toNumber };
};

export default usePrettyNumbers;