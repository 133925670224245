<template>
  <SubNav class="subnav-position" :selected="'investments'" :id="id" />
  <main>
    <div v-if="investments" class="subnav-detail">
      <div v-if="error">{{ error }}</div>
      <div v-for="investment in investments" :key="investment.id">
        <router-link
          class="offering-link"
          :to="{
            name: 'InvestmentDetails',
            params: {
              offeringId: investment.offeringId,
              investmentId: investment.id
            }
          }"
        >
          <div
            class="row single "
            :class="{
              newInvestment: investment.status == 'created',
              c2mError: investment.status == 'C2MError',
              cancelInvestment: investment.status == 'cancelled',
              acceptedInvestment: investment.status == 'accepted'
            }"
          >
            <div
              v-if="investment.investmentType == 'entity'"
              class="name col-md"
            >
              {{ investment.entityName }}
            </div>
            <div v-else class="name col-md">
              {{ investment.primaryFullName }}
            </div>
            <div class="name col-md">
              {{ investment.offeringName }}
            </div>
            <div class="name col-md">
              {{ investment.investmentType }}
            </div>
            <div class="name col-md">
              ${{ numberWithCommas(investment.totalInvestment) }}
            </div>
            <div class="name col-md">
              {{ investment.status }}
            </div>
            <div class="name col-md-1">{{ investment.paymentMethod }}</div>
            <div class="name col-md-3">
              {{ investment.createdAt.toDate().toLocaleString() }}
            </div>
          </div>
        </router-link>
      </div>
      <div class="text-center" v-if="investments && investments.length == 0">
        <p>
          This is where your investors will be listed when someone invests in
          this offering.
        </p>
      </div>
    </div>
  </main>
  <Footer v-if="investments" />
</template>

<script>
import SubNav from "@/components/navigation/SubNav.vue";
import getCollection from "@/composables/getCollection";
import Footer from "@/components/navigation/Footer.vue";
import getUser from "@/composables/getUser.js";
import usePrettyNumbers from "@/composables/usePrettyNumbers";

export default {
  props: ["id"],
  components: { SubNav, Footer },
  setup(props) {
    const { user } = getUser();
    const { error, documents: investments } = getCollection(
      "investments",
      [
        ["issuerId", "==", user.value.uid],
        ["offeringId", "==", props.id]
      ],
      ["createdAt", "desc"]
    );

    const { numberWithCommas } = usePrettyNumbers();

    return {
      numberWithCommas,
      investments,
      error
    };
  }
};
</script>

<style>
</style>